<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent">
      <article class="tile is-child">
        <h4
          v-if="!IsEditMode"
          class="title is-5"
        >
          Add a new key
        </h4>
        <h4
          v-if="IsEditMode"
          class="title is-5"
        >
          Edit the key
        </h4>

        <div class="columns">
          <div :class="[$style.area, 'column', 'area']">
            <div :class="[$style.control, 'control']">
              <label class="label">Name</label>
              <div :class="[$style.control, 'control']">
                <input
                  v-model="apiKey.Name"
                  class="input"
                  type="text"
                  placeholder="Name or description of the key"
                >
              </div>
            </div>

            <div v-if="!IsEditMode">
              <div :class="[$style.control, 'control']">
                <label class="label">Type</label>
                <div :class="[$style.control, 'control']">
                  <div class="select">
                    <select v-model="apiKey.Type">
                      <option :value="ApiKeyTypes.ActionsAADAppOnly">
                        Admin permissions
                      </option>
                      <option :value="ApiKeyTypes.ActionsSPCreds">
                        Custom user credentials
                      </option>
                      <option :value="ApiKeyTypes.Actions">
                        Basic
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <basic-form
                v-if="apiKey.Type == ApiKeyTypes.Actions"
                :api-key="apiKey"
              />

              <aad-admin-form
                v-if="apiKey.Type == ApiKeyTypes.ActionsAADAppOnly"
                :api-key="$v.apiKey"
              />
              <spcreds-form
                v-if="apiKey.Type == ApiKeyTypes.ActionsSPCreds"
                :api-key="$v.apiKey"
              />
            </div>
          </div>
        </div>

        <!-- controls -->
        <div :class="[$style.buttons, 'buttons']">
          <button
            :class="[$style.button, 'button', 'is-primary']"
            :disabled="!IsFormValid"
            @click="save"
          >
            {{ IsEditMode ? 'Edit' : 'Create' }}
          </button>
          <button
            :class="[$style.button, 'button', 'is-light']"
            @click="cancel"
          >
            Cancel
          </button>

          <button
            v-if="IsEditMode"
            :class="[$style.button, $style['button--right'], 'button', 'is-white']"
            @click="remove"
          >
            <span :class="$style.button__icon">
              <font-awesome-icon :icon="['fal', 'trash-can']" />
            </span>
            Delete
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useApiKeysStore } from '@/stores';
import Confirm from '@/components/modals/confirm.vue';
import { required } from '@/utils/validators.js';
import aadAdmin from './forms/aad-admin.vue';
import spcreds from './forms/spcreds.vue';
import basic from './forms/basic.vue';
import ApiKeyTypes from '@/data/common/api-key-types.js';
import OAuthClient from '@/views/documents/processes/services/oauth-client.js';

function isEmpty(str) {
  return (!str || str.length === 0);
}

export default {
  name: 'KeyForm',

  components: {
    'aad-admin-form': aadAdmin,
    'spcreds-form': spcreds,
    'basic-form': basic,
  },
  props: {
    selectedKey: {
      type: Object,
      required: true,
    },
    indexKey: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      ApiKeyTypes,
      OAuthClient: new OAuthClient(),
      apiKey: this.selectedKey,
    };
  },

  computed: {
    IsEditMode() {
      return !isEmpty(this.apiKey.Id);
    },
    IsFormValid() {
      if (isEmpty(this.apiKey.Name)) {
        return false;
      }

      if (this.IsEditMode) return true;

      if (
        this.apiKey.Type === this.ApiKeyTypes.ActionsAADDelegated
        && isEmpty(this.apiKey.AADTenant)
      ) {
        return false;
      }

      if (this.apiKey.Type === this.ApiKeyTypes.Basic || !this.apiKey.Type) {
        return false;
      }

      return true;
    },
    isAdminConsent() {
      return this.apiKey.Type === ApiKeyTypes.AADAppOnly
        || this.apiKey.Type === ApiKeyTypes.ActionsAADAppOnly;
    },
    Text(){
      if (this.mode === 'Testing') return 'Free process runs with watermark inside document.'
      return 'You will be charged for action runs.'
    }
  },

  mounted() {
    this.apiKey.Type = ApiKeyTypes.ActionsAADAppOnly;
  },

  validations() {
    if (this.apiKey.Type === this.ApiKeyTypes.ActionsSPCreds) {
      return {
        apiKey: {
          SPLogin: {
            required,
          },
          SPPassword: {
            required,
          },
        },
      };
    }
    if (this.apiKey.Type === this.ApiKeyTypes.ActionsAADAppOnly) {
      return {
        apiKey: {
          AADTenant: {
            required,
          },
        },
      };
    }
    return {};
  },

  methods: {
    ...mapActions(useApiKeysStore, ['consentRequest', 'loadOauthSettings']),
    async OpenAdminConsentDialog() {
      const domain = this.getDomain(this.apiKey.AADTenant);
      const { error } = await this.consentRequest({ domain, apiKey: this.apiKey });
      if (error) {
        console.log(error);
        window.alert('The specified domain name was not found');
      }
    },

    async OpenUserConsentDialog() {
      const oauth = await this.loadOauthSettings();
      const domain = this.getDomain(this.apiKey.AADTenant);
      const authority = domain.replace('sharepoint.com', 'onmicrosoft.com');

      const query = new URLSearchParams(
        {
          response_type: 'code',
          prompt: 'select_account',
          scope: `https://${domain}/.default offline_access openid profile`,
          client_id: oauth.data.clientId,
          redirect_uri: oauth.data.redirectUrl,
        },
      ).toString();

      const consentUrl = `https://login.microsoftonline.com/${authority}/oauth2/v2.0/authorize?${query}`;

      const func = async (event) => {
        if (event.data !== Object(event.data) && event.data && event.data.indexOf('?code=') !== -1) {
          this.consentCode = event.data.slice(6);
          this.$emit('create', this.consentCode);

          return true;
        }

        return false;
      };

      await this.oauthClient.showPopup(consentUrl, func.bind(this));
    },

    async save() {
      this.$v.$touch();
      if (!this.IsEditMode && this.$v.$invalid) return;

      if (this.IsEditMode) {
        this.$emit('save');
        return;
      }

      this.apiKey.IsExperimental = this.mode === 'Testing'
      
      if (this.isAdminConsent) {
        await this.OpenAdminConsentDialog();
      }

      if (this.apiKey.Type === ApiKeyTypes.ActionsAADDelegated) {
        await this.OpenUserConsentDialog();
      } else {
        this.$emit('create');
      }
    },

    cancel() {
      this.$emit('cancel');
    },

    getDomain(url) {
      let domain = url
        .replace(/^https?:\/\//, '')
        .split('/')[0]
        .split('.sharepoint.com')[0]
        .trim();
      domain += '.sharepoint.com';
      return domain;
    },

    remove() {
      this.$modal.show(
        Confirm,
        {
          message: 'Are you sure you want to delete this key?',
          submitBtnText: 'Delete',
          closeBtnText: 'Cancel',
          submitBtnType: 'is-danger',
          onSubmit: () => {
            this.$emit('remove-key', this.indexKey);
            this.$emit('cancel');
          },
        },
        {
          width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },
  },
};

</script>

<style lang="scss" module>
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

  .area {
    max-width: 600px;
  }

  .buttons {
    max-width: 576px;
    .button {
      margin: 5px 0 0;

      &--right {
        margin-left: auto;

      }

      &__icon {
        margin-right: 10px;
      }
    }
  }

  .control .button {
    margin: inherit;
  }

  .control.has-addons {
    @include mobile() {
      input {
        width: 100%;
      }

      input.is-expanded {
        flex-shrink: 1;
      }
    }
  }

</style>
