<template>
  <div>
    <keys-table
      v-show="selectedKey === null"
      :api-keys="apiKeys"
      @add-key="addKey"
      @edit-key="edit"
      @remove-key="remove"
    />

    <key-form
      v-if="selectedKey"
      :selected-key="selectedKey"
      :index-key="indexKey"
      @create="create"
      @save="save"
      @cancel="cancel"
      @remove-key="remove"
    />
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useApiKeysStore } from '@/stores/index.js';
import KeysTable from './keys-table.vue';
import KeyForm from './key-form.vue';
import ApiKey from './api-key';

export default {
  name: 'ApiKeysManagement',
  components: { KeysTable, KeyForm },

  data: () => ({
    selectedKey: null,
    indexKey: null,
    apiKeys: [],
  }),

  async mounted() {
    this.setProduct('actions');

    const { data } = await this.getKeys();

    data.forEach((key) => {
      this.apiKeys.push(new ApiKey(key.id, key.name, key.type, key.secretId, key.aadTenant));
    });
  },

  methods: {
    ...mapActions(useApiKeysStore, [
      'setProduct',
      'removeKey',
      'createKey',
      'editKey',
      'getKeys',
    ]),
    addKey() {
      this.selectedKey = new ApiKey();
    },

    edit(index) {
      this.selectedKey = { ...this.apiKeys[index] };
      this.indexKey = index;
    },

    async remove(index) {
      await this.removeKey({ id: this.apiKeys[index].Id });
      this.apiKeys.splice(index, 1);
    },

    async create(code) {
      const { data } = await this.createKey({ code, apiKey: this.selectedKey });
      this.apiKeys.push(new ApiKey(data.id, data.name, data.type, data.secretId, data.aadTenant));
      this.cancel();
    },

    async save() {
      const { data } = await this.editKey({ apiKey: this.selectedKey });
      const item = this.apiKeys.find((i) => i.Id.toLowerCase() === data.id.toLowerCase());
      const index = this.apiKeys.indexOf(item);
      if (index !== -1) this.apiKeys.splice(index, 1);
      this.apiKeys.push(new ApiKey(data.id, data.name, data.type, data.secretId, data.aadTenant));
      this.cancel();
    },

    cancel() {
      this.selectedKey = null;
      this.indexKey = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive {
  display: block;
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
</style>
