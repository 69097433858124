<template>
  <div>
    <div class="notification info">
      <p>
        <!-- This type of key can be used with <a href='https://plumsail.com/docs/actions/v1.x/flow/actions/sharepoint-processing.html' target='_blank'>Plumsail SharePoint</a> connector only. -->

        It allows you to execute Plumsail Actions under specific Office 365 account. Just fill in
        account name (Email) and password of Office 365 user with permissions required for
        actions that you want to execute. For example, if you want to move documents your
        user must have at least contribute permissions in SharePoint.
      </p>
    </div>

    <div class="control">
      <label class="label">Email</label>
      <p class="control">
        <input
          v-model="sPLogin.$model"
          class="input"
          type="email"
          placeholder="admin@plumsail.onmicrosoft.com"
          :class="{ 'is-danger': sPLogin.$error }"
        >
      </p>

      <label class="label">Password</label>
      <p class="control">
        <input
          v-model="sPPassword.$model"
          class="input"
          type="password"
          placeholder="Password"
          :class="{ 'is-danger': sPPassword.$error }"
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpcredsForm',
  props: ['apiKey', 'isEdit'],
  data() {
    return {
      sPLogin: this.apiKey.SPLogin,
      sPPassword: this.apiKey.SPPassword,
    };
  },
};
</script>
