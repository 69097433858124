<template>
  <div>
    <div class="notification info">
      <p>
        Plumsail Actions will connect to SharePoint using permissions of tenant admin.
        Fill SharePoint Online address below and grant them by signing in under SharePoint tenant admin user
      </p>
    </div>

    <div class="control">
      <label class="label">Your SharePoint Online domain (yourdomain.sharepoint.com)</label>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            v-model="aADTenant.$model"
            class="input"
            type="text"
            placeholder="mycompany"
            :class="{ 'is-danger': aADTenant.$error }"
          >
        </p>
        <p class="control">
          <a
            class="button is-static"
            disabled
          >.sharepoint.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AadAdminForm',
  props: ['apiKey', 'isEdit'],
  data() {
    return {
      aADTenant: this.apiKey.AADTenant,
    };
  },
};
</script>
