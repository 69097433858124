// TODO: to make shared (data/common?)
export default class ApiKey {
    constructor(id = '', name = '', type = null, secretId = '', aadTenant = '') {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.SecretId = secretId;
        this.AADTenant = aadTenant;
        this.SPLogin = '';
        this.SPPassword = '';
    }
}
