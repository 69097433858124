export default class OAuthClient {
    handler = null;

    constructor (settings = {}) {
        this.settings = { ...settings, width: settings.width || 700, height: settings.height || 700 }

        this.popupWindow = (url, title, win, w, h) => {
            const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2)
            const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2)

            const settings = `toolbar=no, menubar=no, width=${w}, height=${h}, top=${y}, left=${x}`

            return win.open(url, title, settings)
        }
    }

    async showPopup (url, callback) {
        const { width, height } = this.settings
        window.removeEventListener('message', this.handler, false)
        this.popupWindow(url, 'popup', window, width, height)

        this.handler = async event => {
            if (await callback(event)) {
                window.removeEventListener('message', this.handler, false)
            }
        }

        window.addEventListener('message', this.handler, false)
    }
}
