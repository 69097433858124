<template>
  <div> 
      <div class="notification info">
        <p>
            It allows you to execute Plumsail Actions that don't require access to SharePoint. Use it with Parse CSV, Regular Expression Match and other similar actions.
        </p>
     </div>
  </div>
</template>

<script>
  export default {
    name: 'basic-form',
    props: ['apiKey', 'isEdit'],
  }
  </script>
